import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="raleway-font">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <footer className="footer">
        <div className="desktop-component load-in">
          AZ ROC Lic #188403, #188404, #188405{" "}
          <span className="footer-pop-text">|</span> NV ROC Lic #0052411,
          #0052410 ($50,000 Bond Limit in NV){" "}
          <span className="footer-pop-text">
            © {new Date().getFullYear()}, Green Energy Inc.
          </span>
        </div>
        <div className="mobile-component load-in">
          AZ ROC Lic #188403, #188404, #188405
          <br />
          NV ROC Lic #0052411, #0052410
          <br />
          ($50,000 Bond Limit in NV)
          <br />
          <span className="footer-pop-text">
            © {new Date().getFullYear()}, Green Energy Inc.
          </span>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
